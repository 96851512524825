.card_container{
    display: flex;
    flex-direction: column;
    border:2px greenyellow;
    height: 60vh;
    width:100%;
    border-radius: 5px;
    position: relative;
    background-color: rgb(29, 158, 29);
    text-align: left;
    padding: 30px 20px;
    box-shadow: 24px 25px 6px -6px gray;
    font-family: 'Poppins'; 
    
}
.card_container h1{
    font-weight: bolder;
}
.card_container span{
    color: gray;
}
.card_container img{
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 150px;
    border:2px solid white;
    border-radius: 10px;
}
.card_container button{
    cursor: pointer;
    margin: 10px 0;
    width: 8vw;
    height: 2vw;
    border-radius: 10px;
    color: black;
    box-shadow: 9px 5px 0px  gray;

}
.card_container{
    position: relative;
    animation-name: slide ;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}
@keyframes slide{ 

    0%   {}
    100% {}

}