.bk-bg {
    padding-top: 100px;
    margin: 0%;
    background-image: url(../public/home-header-footer.png);
    width: 100%;
    background-size: 100%;
    height: auto;
    background-attachment: fixed;
}

.home-text1 {
    margin-top: 0%;
    padding: 30px 0px 0px 101px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 60px;
    color: #FFFFFF;
    margin-bottom: 0%;
}

.home-hr {
    width: 560px;
    height: 3px;
    border-style: none;
    background: #50A060;
    border-radius: 69px;
    text-align: left;
    margin-left: 101px;
}
.home-text2 {
    padding: 0px 0px 0px 101px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 60px;
    color: #FFFFFF;
    margin: 0% 0% 10px 0%;
}

.why-was{
    margin: 0px 0px 10px 101px;
    width: 180px;
    height: 40px;
    background: #50A060;
    border-radius: 63.4723px;
    border-style: none;
    color: #fff;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1.25rem;
    cursor: pointer;
}

.dust-img{
    margin: 120px 120px 0px 120px;
    display: flex;
    justify-content: space-between;
}

.request-waste {
    display: flex;
    margin: 0%;
    width: 100%;
    background-size: 100%;
    height: auto;
}
.half1{
    width: 50%;
}
.request-waste-text1 {
    font-family: 'Kaushan Script';
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 36px;
    text-align: left;
    margin: 0px 0px 0px 101px;
    padding-top: 100px;
    color: #206A3F;
}

.request-waste-text2 {
    margin: 0px 10% 0px 101px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 70px;
    line-height: 77px;
    color: #E9E0E0;
}

.request-waste-text3 {
    margin: 0px 10% 0px 101px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: rgba(255, 255, 255, 0.6);
}

.request-waste-btn {
        margin: 25px 0px 10px 101px;
        width: 170px;
        height: 55px;
        background: #50A060;
        border-radius: 63.4723px;
        border-style: none;
        color: #fff;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 1.6rem;
}


.coin_bounce{
    position: relative;
    animation-name: bounce;
    animation-duration: 3s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-delay: 1.5s;
    animation-timing-function: ;
}
@keyframes bounce {
  0%   {top: -50%; opacity: 0;}
  25%  {top: -60%;}
  32%  {top: -70%;}
  50%  {top: -80%;}
  75%  {top: -90%;}
  100% {top: -100%; }
  } 
  .garbage_down{
    position: relative;
    animation-name: down;
    animation-duration: 3s;
    animation-delay: 0s;
    animation-iteration-count: infinite;
  }
  @keyframes down {
    0%   {top: -80%; opacity: 1;}
    25%  {top:-60%; opacity: .7;}
    32%  {top:-45%;opacity: .5;}
    50%  {top:-35%;opacity: .4;}
    100%  {top:-25%;opacity: .0;}
    } 

  /* .plastic_bounce1{
    position: relative;
    animation-name: plasticBounce;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    left:-150px;
  }
  @keyframes plasticBounce {
    100%   {top:-320px;opacity:0;}
    75%  {top:-200px;}
    65%  {top:-80px;}
    50%  {top:0px;}
    25%  {top:10px;}
    0% {top:30px; }
    } */

    /* .line{
        position: absolute;
    } */
 
    .half2{
            display:flex;
            flex-direction:column;
    }
    .half2 img
    {
        border-radius: 5%;
        width: 50em;
        height: 30em;
        margin-top: 5em;
        border: 3px solid white;
    }
    .half2 div{
        z-index: 0;
    }
    .half2 div:nth-child(2)
    {
        
        z-index: 999;

    }