.login {
    padding-top: 150px;
    display: flex;
    column-gap: 20px;
}

.log-form {
    padding-left: 15%;
    width: 50%;
}

.log-img {
    padding-right: 15%;
    width: 50%
}

.wb {
    margin: 0%;
    padding: 30px 0px 0px 52px;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 36px;
}

.li2 {
    margin: 0%;
    padding-left: 52px;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 36px;
    color: #50A060;
}

.log-email,
.log-pass {
    margin: 20px 0px 0px 52px;
    width: 350px;
    height: 40px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid black;
    font-size: 120%;
    letter-spacing: 2px;
    background-color: transparent;
    outline: none;
}

.log-email::placeholder {
    padding: 2px;
}

.log-pass::placeholder {
    padding: 2px;
}

.log-pass {
    margin-bottom: 30px;
}

.login-btn {
    margin-left: 53px;
    width: 270px;
    height: 40px;
    background-color: #000000;
    color: #fff;
    font-size: 23px;
    border-radius: 13px;
    border-style: none;
    cursor: pointer;
}

.gog-login-btn,
.twt-login-btn {
    margin: 15px 0px 0px 0px;
    display: flex;
    column-gap: 20px;
    margin-left: 53px;
    width: 270px;
    height: 40px;
    background-color: #FFFFFF;
    border: 1px solid #000000;
    border-radius: 13px;
    cursor: pointer;
}

.ins-txt {
    margin: 5px 0px;
    font-size: 20px;
}

.g {
    margin: 3px 0px 0px 10px;
}

.t {
    margin: 6px 0px 0px 10px;
}

.sin {
    display: flex;
    column-gap: 10px;
}

.sinup-red {
    margin-left: 60px;
    font-family: 'Poppins';
    font-size: 15px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.49);
}

.sinup {
    font-family: 'Poppins';
    font-size: 15px;
    line-height: 22px;
    color: #000000;
    text-decoration: underline;
    cursor: pointer;
}

.loginimage {
    background-color: #171717;
    border-radius: 10% 0%;
    width: 100%;
}