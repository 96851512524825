
.readme{
    display: flex;
    flex-direction: column;
    font-family: "Poppins"; 
    width: 100%;
    height: 100%;
    background-color: white;
    padding: 0 0% 5% 0;
    margin-top: 5%;
    justify-content: center;    
}

.readme span {
    color: green;
}
 .readme1{
    position: relative;
    margin-left: 8%;
    font-size: 32px;   
}
.readme2{
   margin-right: 7%;
}
